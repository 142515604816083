@font-face {
    font-family:myfontl;
    src: url(../../assets/FONT/calibril.ttf);
}
@font-face {
    font-family:myfont;
    src: url(../../assets/FONT/calibri.ttf);
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-image: url(../../assets/Image/Background-01.png) ;
    background-size:cover;
    /* background-position: top left ; */
    background-repeat: no-repeat;
    /* overflow: hidden; */
    margin: 0;
    padding: 0;
}
/* .desktop{

    background-image: url(../Image/Image-4.png), url(../Image/Background-01.png);
    background-size: 1000px,cover;
    background-position: right , top left;
    background-repeat: no-repeat;
    height: 100vh;
    margin-top: -22px;
}
.mobile{
    background-image: url(../Image/Image-4.png), url(../Image/Background-01-mobile.png);
    background-size: 500px,cover;
    background-position: center bottom , top left;
    background-repeat: no-repeat;
    height: 100vh;
    margin-top: -22px;
} */

 .btn{
    color: #FFFFFF;
    background-color: #212529;
}

.btn:hover{
    background-color: #E2D9C0;
    color: #FFFFFF;
}

h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    padding: 0;
}

h1,h2,h3,h4,h5,h6{
  font-family: myfont !important;
}

p{
    font-family: myfontl !important;
   
}

.kebijakan{
    text-decoration: none;
    color: #4B4848;
}

/* .hp{
    background-image: url(../Image/Image-5.png);
    background-size: cover;
    height: 100vh;
} */

/* @media (max-width:768px) {
    .ios{
        width:120%;
    }
    .google{
        width:80%;
    }
    .hp{
    background-image: url(../Image/Image-4.png);
    background-size: cover;
    height: 60vh;
    width: 80%;
    background-position:top right;
    }
} */

@media only screen and (device-width: 1024px) {
   
            .logo{
                width: 30%;
            }
            img.hp{
            text-align: center;
            width: 98%;
            margin-top: 50px;
            } 

            .font-ios{
                font-size: 22px;
            }

            .font-google{
                font-size: 22px;
                
            }
            .donlot{
                font-size: 12px;
                margin-top: -10px;
            }
            .get{
                font-size: 12px;
                margin-top: -10px;
            }
            .ios{
                width:140%;
            }
            .google{
                width:90%;
            }
            .text{
                font-size: large;
            }
}

@media (min-width: 1440px) {

            .logo{
                width: 30%;
            }
            .ios{
                width:80%;
                /* margin-top: 10px; */
            }
            .google{
                width:90%;
                margin-top: 2px;
            
            }
            img.hp{
            text-align: center;
            width: 80%;
            margin-top: 0px;
            } 
           .donlot{
                font-size: 18px;
                /* margin-top: -10px; */
            }
            .get{
                font-size: 18px;
                /* margin-top: -10px; */
            }
            .font-ios{
                font-size: 34px;
            }

            .font-google{
                font-size: 33px;
                
            }
           
            
} 

    @media only screen and (min-width: 1200px) {
        /* styles for browsers larger than 960px; */
         .logo{
                width: 30%;
            }
            .ios{
                width:150%;
            }
            .google{
                width:90%;
                
            }

            img.hp{
            text-align: end;
            width: 90%;
            margin-top: 70px;
            }

             .font-ios{
                font-size: 28px;
            }

            .font-google{
                font-size: 28px;
                
            }
            .donlot{
                font-size: 14px;
                margin-top: -10px;
            }
            .get{
                font-size: 14px;
                margin-top: -11px;
            }
            .text{
                font-size: 20px;
            }
    }
    @media only screen and (min-width: 1441px) {
        /* styles for browsers larger than 1440px; */
             
            .logo{
                width: 30%;
            }
            .ios{
                width:110%;
                margin-top: 10px;
                padding-bottom: 10px;
            }
            .google{
                width:68%;
                margin-top: 10px;
                padding-bottom: 10px;
            }
            img.hp{
            text-align: center;
            width: 80%;
            margin-top: 0px;
            } 
           .donlot{
                font-size: 18px;
                margin-top: 2px;
            }
            .get{
                font-size: 18px;
                margin-top: 3px;
            }
            .font-ios{
                font-size: 34px;
            }

            .font-google{
                font-size: 33px;
                
            }
            
    }
    @media only screen and (min-width: 2000px) {
        /* for sumo sized (mac) screens */
       
    }
     @media only screen and (device-width: 768px) {
       /* default iPad screens */   

            img.logo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 40%;
            }
            .ios{
                width:100%;
            }
            .google{
                width:70%;
            }
            .tab1{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                margin-top: 250px;
            }
            .tab2{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
            .hp{
            /* background-image: url(../Image/Image-5.png); */
            background-size: cover;
            height: 100vh;
            width: 80%;
            background-position:right bottom;
            margin-right: 0px ;
            overflow-x:hidden;
            overflow-y: hidden;
            margin-top: -50px;
            
            } 

            .text{
                margin-top: -550px;
                text-align: center;
            }
            .row-tab{
                margin-top: 500px;
            }
            body{
                background-image: url(../../assets/Image/Background-01-mobile.png);
                background-size: cover;
            }
     } 

    @media only screen and (max-width: 480px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
        img.logo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 40%;
                margin-top: 30px;
            }
            .ios{
                width:120%;
            }
            .google{
                width:75%;
                display: block;
                margin-left: 15px;
            }
            .tab1{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                margin-top: 250px;
            }
            .tab2{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
            .hp{
            /* background-image: url(../Image/Image-5.png); */
            background-size: cover;
            height: 60vh;
            width: 100%;
            background-position:center;
            margin-right: 0px ;
            overflow:hidden;
            margin-top: -20px;
            
            } 

            .text{
                margin-top: -500px;
                text-align: center;
                font-size: 16px;
            }
            body{
                background-image: url(../../assets/Image/Background-01-mobile.png);
                background-size: cover;
            }
            /* .btn{
                width: 80%;
            } */
            .font-ios{
                font-size: 22px;
            }

            .font-google{
                font-size: 22px;
                
            }
            .donlot{
                font-size: 12px;
                margin-top: -10px;
            }
            .get{
                /* font-size: 12px; */
                margin-top: -10px;
            }
            
       
    }
    @media only screen and (max-width: 320px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
        img.logo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
                margin-top: 30px;
            }
            .ios{
                width:150%;
            }
            .google{
                width:100%;
                display: block;
                margin-left: 15px;
            }
            .tab1{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                margin-top: 200px;
            }
            .tab2{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
            .hp{
            /* background-image: url(../Image/Image-5.png); */
            background-size: cover;
            height: 70vh;
            width: 100%;
            background-position:center;
            margin-right: 0px ;
            overflow:hidden;
            margin-top: -50px;
            
            } 

            .text{
                margin-top: -450px;
                text-align: center;
                font-size: 16px;
            }
            body{
                background-image: url(../../assets/Image/Background-01-mobile.png);
                background-size: cover;
            }
            /* .btn{
                width: 80%;
            } */
            .font-ios{
                font-size: 22px;
            }

            .font-google{
                font-size: 22px;
                
            }
            .donlot{
                font-size: 10px;
                margin-top: -20px;
            }
            .get{
                /* font-size: 12px; */
                margin-top: -20px;
            }
            
       
    }
    /* different techniques for iPad screening */
    @media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation:portrait) {
      /* For portrait layouts only */
     
           
             img.logo{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 40%;
            }
            .ios{
                width:100%;
            }
            .google{
                width:70%;
            }
            .tab1{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                margin-top: 300px;
            }
            .tab2{
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
            .hp{
            /* background-image: url(../Image/Image-5.png); */
            background-size: cover;
            height: 70vh;
            width: 90%;
            background-position:right bottom;
            margin-right: 0px ;
            overflow:hidden;
            margin-top: -50px;
            
            } 

            .text{
                margin-top: -650px;
                text-align: center;
            }
            .row-tab{
                margin-top: 500px;
            }
     
    
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* For landscape layouts only */
    
    }