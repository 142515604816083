/*===========================================================

   Template Name: iDocs - One Page Documentation HTML Template
   Author: Harnish Design
   Template URL: http://demo.harnishdesign.net/html/idocs
   Author URL: https://themeforest.net/user/harnishdesign
   File Description : Main css file of the template
	
=================================================
  Table of Contents
=================================================

	1. Basic
	2. Helpers Classes
	3. Layouts
		3.1 Side Navigation
		3.2 Docs Content
		3.3 Section Divider
	4. Header
		4.1 Main Navigation
	5 Elements
		5.1 List Style
		5.2 Changelog
		5.3 Accordion & Toggle
		5.4 Nav
		5.5 Tabs
		5.6 Popup Img
		5.7 Featured Box
	6 Footer
		6.1 Social Icons
		6.2 Back to Top
	7 Extra

=======================================================*/
/* =================================== */
/*  1. Basic Style 
/* =================================== */
/* body, html {
  height: 100%;
}

body {
  background: #dddddd;
  color: #4c4d4d;
} */
body{
    background-image: url(../../assets/Image/Background-01.png) ;
    background-size:cover;
    /* background-position: top left ; */
    background-repeat: no-repeat;
    /* overflow: hidden; */
    margin: 0;
    padding: 0;
}

/*-------- Preloader --------*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999999 !important;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.preloader .lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  top: 50%;
  left: 50%;
}
.preloader .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.preloader .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.preloader .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.preloader .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.preloader .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/*--- Preloader Magnific Popup ----*/
.mfp-container .preloader {
  background: transparent;
}
.mfp-container .preloader .lds-ellipsis div {
  background: #fff;
}

::selection {
  background: #0366d6;
  color: #fff;
  text-shadow: none;
}

code {
  padding: 2px 5px;
  background-color: #f9f2f4;
  border-radius: 4px;
}

form {
  padding: 0;
  margin: 0;
  display: inline;
}

img {
  vertical-align: inherit;
}

a, a:focus {
  color: #0366d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:hover, a:active {
  color: #0355b3;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:focus, a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
  outline: none;
}

p {
  line-height: 1.8;
}

blockquote {
  border-left: 5px solid #eee;
  padding: 10px 20px;
}

iframe {
  border: 0 !important;
}

/* h1, h2, h3, h4, h5, h6 {
  color: #252b33;
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
  font-family: Roboto, sans-serif;
} */

/* h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.2rem;
} */

dl, ol, ul, pre, blockquote, .table {
  margin-bottom: 1.8rem;
}

/*=== Highlight Js ===*/
.hljs {
  padding: 1.5rem;
}

/* =================================== */
/*  2. Helpers Classes
/* =================================== */
/* Box Shadow */
.shadow-md {
  -webkit-box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
}

/* Border Radius */
.rounded-lg {
  border-radius: 0.6rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* Border Size */
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

/* Text Size */
/* .text-0 {
  font-size: 11px !important;
  font-size: 0.6875rem !important;
}

.text-1 {
  font-size: 12px !important;
  font-size: 0.75rem !important;
}

.text-2 {
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.text-3 {
  font-size: 16px !important;
  font-size: 1rem !important;
}

.text-4 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
}

.text-5 {
  font-size: 21px !important;
  font-size: 1.3125rem !important;
}

.text-6 {
  font-size: 24px !important;
  font-size: 1.50rem !important;
}

.text-7 {
  font-size: 28px !important;
  font-size: 1.75rem !important;
}

.text-8 {
  font-size: 32px !important;
  font-size: 2rem !important;
}

.text-9 {
  font-size: 36px !important;
  font-size: 2.25rem !important;
}

.text-10 {
  font-size: 40px !important;
  font-size: 2.50rem !important;
}

.text-11 {
  font-size: 2.75rem !important;
} */
@media (max-width: 992px) {
  .text-11 {
    font-size: calc(1.4rem + 2.1774193548vw)  !important;
  }
}

/* .text-12 {
  font-size: 3rem !important;
} */
@media (max-width: 992px) {
  .text-12 {
    font-size: calc(1.425rem + 2.5403225806vw)  !important;
  }
}

.text-13 {
  font-size: 3.25rem !important;
}
@media (max-width: 992px) {
  .text-13 {
    font-size: calc(1.45rem + 2.9032258065vw)  !important;
  }
}

.text-14 {
  font-size: 3.5rem !important;
}
@media (max-width: 992px) {
  .text-14 {
    font-size: calc(1.475rem + 3.2661290323vw)  !important;
  }
}

.text-15 {
  font-size: 3.75rem !important;
}
@media (max-width: 992px) {
  .text-15 {
    font-size: calc(1.5rem + 3.6290322581vw)  !important;
  }
}

.text-16 {
  font-size: 4rem !important;
}
@media (max-width: 992px) {
  .text-16 {
    font-size: calc(1.525rem + 3.9919354839vw)  !important;
  }
}

.text-17 {
  font-size: 4.5rem !important;
}
@media (max-width: 992px) {
  .text-17 {
    font-size: calc(1.575rem + 4.7177419355vw)  !important;
  }
}

.text-18 {
  font-size: 5rem !important;
}
@media (max-width: 992px) {
  .text-18 {
    font-size: calc(1.625rem + 5.4435483871vw)  !important;
  }
}

.text-19 {
  font-size: 5.25rem !important;
}
@media (max-width: 992px) {
  .text-19 {
    font-size: calc(1.65rem + 5.8064516129vw)  !important;
  }
}

.text-20 {
  font-size: 5.75rem !important;
}
@media (max-width: 992px) {
  .text-20 {
    font-size: calc(1.7rem + 6.5322580645vw)  !important;
  }
}

.text-21 {
  font-size: 6.5rem !important;
}
@media (max-width: 992px) {
  .text-21 {
    font-size: calc(1.775rem + 7.6209677419vw)  !important;
  }
}

.text-22 {
  font-size: 7rem !important;
}
@media (max-width: 992px) {
  .text-22 {
    font-size: calc(1.825rem + 8.3467741935vw)  !important;
  }
}

.text-23 {
  font-size: 7.75rem !important;
}
@media (max-width: 992px) {
  .text-23 {
    font-size: calc(1.9rem + 9.435483871vw)  !important;
  }
}

.text-24 {
  font-size: 8.25rem !important;
}
@media (max-width: 992px) {
  .text-24 {
    font-size: calc(1.95rem + 10.1612903226vw)  !important;
  }
}

.text-25 {
  font-size: 9rem !important;
}
@media (max-width: 992px) {
  .text-25 {
    font-size: calc(2.025rem + 11.25vw)  !important;
  }
}

.text-11, .text-12, .text-13, .text-14, .text-15, .text-16, .text-17, .text-18, .text-19, .text-20, .text-21, .text-22, .text-23, .text-24, .text-25 {
  line-height: 1.3;
}

/* Line height */
.line-height-07 {
  line-height: 0.7 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.4 !important;
}

.line-height-4 {
  line-height: 1.6 !important;
}

.line-height-5 {
  line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/* Background light */
.bg-light-1 {
  background-color: #e9ecef !important;
}

.bg-light-2 {
  background-color: #dee2e6 !important;
}

.bg-light-3 {
  background-color: #ced4da !important;
}

.bg-light-4 {
  background-color: #adb5bd !important;
}

/* Background Dark */
.bg-dark {
  background-color: #111418 !important;
}

.bg-dark-1 {
  background-color: #212529 !important;
}

.bg-dark-2 {
  background-color: #343a40 !important;
}

.bg-dark-3 {
  background-color: #495057 !important;
}

.bg-dark-4 {
  background-color: #6c757d !important;
}

/* Progress Bar */
.progress-sm {
  height: 0.5rem !important;
}

.progress-lg {
  height: 1.5rem !important;
}

hr {
  border-top: 1px solid rgba(16, 85, 96, 0.1);
}

/* =================================== */
/*  3. Layouts
/* =================================== */
#main-wrapper {
  background: #fff;
}

.box #main-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.box .idocs-navigation {
  left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
/*=== 3.1 Side Navigation ===*/
.idocs-navigation {
  position: fixed;
  top: 70px;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  width: 260px;
  height: calc(100% - 70px);
  z-index: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
}
.idocs-navigation > .nav {
  padding: 30px 0;
}
.idocs-navigation .nav .nav-item {
  position: relative;
}
.idocs-navigation .nav .nav {
  margin: 0 0 5px;
}
.idocs-navigation .nav .nav-link {
  position: relative;
  padding: 6px 30px;
  line-height: 25px;
  font-weight: 600;
}
.idocs-navigation .nav .nav-item:hover > .nav-link, .idocs-navigation .nav .nav-item .nav-link.active {
  font-weight: 700;
}
.idocs-navigation .nav .nav {
  display: none;
  border-left: 1px solid regba(0, 0, 0, 0.3);
}
.idocs-navigation .nav .nav .nav-item .nav-link:after {
  content: "";
  position: absolute;
  left: 30px;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  width: 1px;
  top: 0;
}
.idocs-navigation .nav .nav .nav-item .nav-link.active:after {
  border-color: #0366d6;
  border-width: 2px;
}
.idocs-navigation .nav .nav-item .nav-link.active + .nav {
  display: block;
}
.idocs-navigation .nav .nav .nav-link {
  color: #6a6a6a;
  padding: 4px 30px 4px 45px;
  font-size: 15px;
  text-transform: none;
}
.idocs-navigation .nav .nav .nav .nav-link {
  padding: 4px 30px 4px 60px;
  font-size: 15px;
}
.idocs-navigation .nav .nav .nav .nav-item .nav-link:after {
  left: 45px;
}
.idocs-navigation > .nav > .nav-item > .nav-link.active:after {
  position: absolute;
  content: " ";
  top: 50%;
  right: 18px;
  border-color: #000;
  border-top: 2px solid;
  border-right: 2px solid;
  width: 7px;
  height: 7px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.idocs-navigation.docs-navigation-dark .nav .nav-link {
  color: rgba(250, 250, 250, 0.85);
}
.idocs-navigation.docs-navigation-dark .nav .nav .nav-link {
  color: rgba(250, 250, 250, 0.7);
}
.idocs-navigation.docs-navigation-dark .nav .nav .nav-item .nav-link:after {
  border-color: rgba(250, 250, 250, 0.2);
}
.idocs-navigation.docs-navigation-dark .nav .nav .nav-item .nav-link.active:after {
  border-color: #0366d6;
}

/*=== 3.2 Docs Content ===*/
.idocs-content {
  position: relative;
  margin-left: 260px;
  padding: 0px 50px 50px;
  min-height: 750px;
  transition: all 0.3s;
}
.idocs-content section:first-child {
  padding-top: 3rem;
}
.idocs-content ol li, .idocs-content ul li {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .idocs-navigation {
    margin-left: -260px;
  }
  .idocs-navigation.active {
    margin-left: 0;
  }

  .idocs-content {
    margin-left: 0px;
    padding: 0px;
  }
}
/*=== 3.3 Section Divider ===*/
.divider {
  margin: 4rem 0;
}

/* =================================== */
/*  4. Header
/* =================================== */
#header {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#header .navbar {
  padding: 0px;
  min-height: 70px;
}
#header .logo {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

/*=== 4.1 Main Navigation ===*/
.navbar-light .navbar-nav .active > .nav-link {
  color: #0c2f55;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show {
  color: #0c2f55;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #0c2f55;
}

.primary-menu {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: auto !important;
  -webkit-box-ordinal-group: 0;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background: #fff;
  border-bottom: 1px solid #efefef;
}
.primary-menu.bg-transparent {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
.primary-menu.sticky-on {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1020;
  left: 0;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  -webkit-animation: slide-down 0.7s;
  -moz-animation: slide-down 0.7s;
  animation: slide-down 0.7s;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.primary-menu.sticky-on .none-on-sticky {
  display: none !important;
}
.primary-menu ul.navbar-nav > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.primary-menu ul.navbar-nav > li + li {
  margin-left: 2px;
}
.primary-menu ul.navbar-nav > li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.primary-menu ul.navbar-nav > li > a:not(.btn) {
  height: 70px;
  padding: 0px 0.85em;
  color: #252b33;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  position: relative;
}
.primary-menu ul.navbar-nav > li:hover > a:not(.btn), .primary-menu ul.navbar-nav > li > a.active:not(.btn) {
  color: #0366d6;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li a.btn {
  padding: 0.4rem 1.4rem;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
  padding: 8px 0px;
  background-color: transparent;
  text-transform: none;
  color: #777;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
  color: #0366d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown:hover > a:after {
  clear: both;
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  border-width: 0px 7px 6px 7px;
  bottom: 0px;
  left: 50%;
  margin: 0 0 0 -5px;
  z-index: 1022;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu {
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  border: 0px none;
  padding: 10px 15px;
  min-width: 220px;
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  z-index: 1021;
}
.primary-menu ul.navbar-nav > li.dropdown > .dropdown-toggle .arrow {
  display: none;
}
.primary-menu .dropdown-menu-right {
  left: auto !important;
  right: 100% !important;
}
.primary-menu ul.navbar-nav > li.dropdown-mega {
  position: static;
}
.primary-menu ul.navbar-nav > li.dropdown-mega > .dropdown-menu {
  width: 100%;
  padding: 20px 20px;
  margin-left: 0px !important;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
  padding: 5px 5px 5px 20px;
  border-right: 1px solid #eee;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div:last-child {
  border-right: 0;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .sub-title {
  display: block;
  font-size: 16px;
  margin-top: 1rem;
  padding-bottom: 5px;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-submenu {
  list-style-type: none;
  padding-left: 0px;
}
.primary-menu ul.navbar-nav > li a.btn {
  font-size: 14px;
  padding: 0.65rem 2rem;
  text-transform: uppercase;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  left: 100%;
  margin-top: -40px;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-toggle:after {
  border-top: .4em solid transparent;
  border-right: 0;
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow {
  position: absolute;
  min-width: 30px;
  height: 100%;
  right: 0px;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 6px;
  height: 6px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.primary-menu .dropdown-toggle:after {
  content: none;
}
.primary-menu.navbar-line-under-text ul.navbar-nav > li > a:not(.btn):after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  left: 50%;
  right: 0;
  bottom: 14px;
  background-color: transparent;
  color: #fff;
  border-bottom: 2px solid #0366d6;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, 0) translateZ(0);
  -webkit-transform: translate(-50%, 0) translateZ(0);
}
.primary-menu.navbar-line-under-text ul.navbar-nav > li > a:hover:not(.logo):after, .primary-menu.navbar-line-under-text ul.navbar-nav > li > a.active:after {
  width: calc(100% - 0.99em);
}

/*== Color Options ==*/
.primary-menu.navbar-text-light .navbar-toggler span {
  background: #fff;
}

.primary-menu.navbar-text-light .navbar-nav > li > a:not(.btn) {
  color: #fff;
}
.primary-menu.navbar-text-light .navbar-nav > li:hover > a:not(.btn), .primary-menu.navbar-text-light .navbar-nav > li > a.active:not(.btn) {
  color: rgba(250, 250, 250, 0.75);
}

.primary-menu.navbar-text-light.navbar-line-under-text .navbar-nav > li > a:not(.logo):after, .primary-menu.navbar-text-light.navbar-line-under-text .navbar-nav > li > a.active:after {
  border-color: rgba(250, 250, 250, 0.6);
}

.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu {
  background-color: #252A2C;
  color: #fff;
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  background-color: #272c2e;
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown:hover > a:after {
  border-color: transparent transparent #252A2C transparent;
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
  color: rgba(250, 250, 250, 0.8);
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
  color: #fafafa;
  font-weight: 600;
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
  border-color: #3a3a3a;
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu {
  background-color: #0366d6;
  color: #fff;
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  background-color: #0366d6;
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown:hover > a:after {
  border-color: transparent transparent #0366d6 transparent;
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
  color: rgba(250, 250, 250, 0.95);
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
  color: #fafafa;
  font-weight: 600;
}
.primary-menu.navbar-dropdown-primary ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
  border-color: rgba(250, 250, 250, 0.2);
}

/* Hamburger Menu Button */
.navbar-toggler {
  width: 25px;
  height: 30px;
  padding: 10px;
  margin: 18px 15px;
  position: relative;
  border: none;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: block;
}
.navbar-toggler span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #3c3636;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navbar-toggler span:nth-child(1) {
  top: 7px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar-toggler span:nth-child(2) {
  top: 14px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar-toggler span:nth-child(3) {
  top: 21px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar-toggler.show span:nth-child(1) {
  top: 4px;
  left: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.navbar-toggler.show span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.navbar-toggler.show span:nth-child(3) {
  top: 22px;
  left: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navbar-accordion {
  position: initial;
}

.navbar-expand-none .navbar-collapse {
  position: absolute;
  top: 99%;
  right: 0;
  left: 0;
  background: #fff;
  margin-top: 0px;
  z-index: 1000;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.navbar-expand-none .navbar-collapse .navbar-nav {
  overflow: hidden;
  overflow-y: auto;
  max-height: 65vh;
  padding: 15px;
}
.navbar-expand-none ul.navbar-nav li {
  display: block;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 0;
}
.navbar-expand-none ul.navbar-nav li:last-child {
  border: none;
}
.navbar-expand-none ul.navbar-nav li + li {
  margin-left: 0px;
}
.navbar-expand-none ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.show:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.navbar-expand-none ul.navbar-nav li > a:hover:after, .navbar-expand-none ul.navbar-nav li > a.active:after {
  content: none !important;
  width: 0px !important;
}
.navbar-expand-none ul.navbar-nav li.dropdown > .dropdown-toggle .arrow {
  display: block;
}
.navbar-expand-none ul.navbar-nav > li > a:not(.btn) {
  height: auto;
  padding: 8px 0;
  position: relative;
}
.navbar-expand-none ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
  padding: 8px 0;
  position: relative;
}
.navbar-expand-none ul.navbar-nav > li.dropdown:hover > a:after {
  content: none;
}
.navbar-expand-none ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
  -webkit-transform: translate(-50%, -50%) rotate(134deg);
  transform: translate(-50%, -50%) rotate(134deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.navbar-expand-none ul.navbar-nav > li.dropdown .dropdown-menu {
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0px 0px 0px 15px;
}
.navbar-expand-none ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  margin: 0;
}
.navbar-expand-none ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
  padding: 0px 15px;
}
.navbar-expand-none.navbar-text-light .navbar-collapse {
  background: rgba(0, 0, 0, 0.95);
}
.navbar-expand-none.navbar-text-light .navbar-collapse ul.navbar-nav li {
  border-color: rgba(250, 250, 250, 0.15);
}
.navbar-expand-none.navbar-dropdown-dark .navbar-collapse {
  background-color: #252A2C;
}
.navbar-expand-none.navbar-dropdown-primary .navbar-collapse {
  background-color: #0366d6;
}
.navbar-expand-none.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  background-color: #0366d6;
}
.navbar-expand-none.navbar-dropdown-dark ul.navbar-nav li {
  border-color: #444;
}
.navbar-expand-none.navbar-dropdown-dark ul.navbar-nav > li > a {
  color: #a3a2a2;
}
.navbar-expand-none.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
  color: #fff;
}
.navbar-expand-none.navbar-dropdown-primary ul.navbar-nav li {
  border-color: rgba(250, 250, 250, 0.2);
}
.navbar-expand-none.navbar-dropdown-primary ul.navbar-nav > li > a {
  color: rgba(250, 250, 250, 0.8);
}
.navbar-expand-none.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
  color: #fff;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: #fff;
    margin-top: 0px;
    z-index: 1000;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    padding: 15px;
  }
  .navbar-expand-sm ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
  }
  .navbar-expand-sm ul.navbar-nav li:last-child {
    border: none;
  }
  .navbar-expand-sm ul.navbar-nav li + li {
    margin-left: 0px;
  }
  .navbar-expand-sm ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.show:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-sm ul.navbar-nav li > a:hover:after, .navbar-expand-sm ul.navbar-nav li > a.active:after {
    content: none !important;
    width: 0px !important;
  }
  .navbar-expand-sm ul.navbar-nav li.dropdown > .dropdown-toggle .arrow {
    display: block;
  }
  .navbar-expand-sm ul.navbar-nav > li > a:not(.btn) {
    height: auto;
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown:hover > a:after {
    content: none;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-50%, -50%) rotate(134deg);
    transform: translate(-50%, -50%) rotate(134deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown .dropdown-menu {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0px 0px 0px 15px;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    margin: 0;
  }
  .navbar-expand-sm ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    padding: 0px 15px;
  }
  .navbar-expand-sm.navbar-text-light .navbar-collapse {
    background: rgba(0, 0, 0, 0.95);
  }
  .navbar-expand-sm.navbar-text-light .navbar-collapse ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.15);
  }
  .navbar-expand-sm.navbar-dropdown-dark .navbar-collapse {
    background-color: #252A2C;
  }
  .navbar-expand-sm.navbar-dropdown-primary .navbar-collapse {
    background-color: #0366d6;
  }
  .navbar-expand-sm.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    background-color: #0366d6;
  }
  .navbar-expand-sm.navbar-dropdown-dark ul.navbar-nav li {
    border-color: #444;
  }
  .navbar-expand-sm.navbar-dropdown-dark ul.navbar-nav > li > a {
    color: #a3a2a2;
  }
  .navbar-expand-sm.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
    color: #fff;
  }
  .navbar-expand-sm.navbar-dropdown-primary ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.2);
  }
  .navbar-expand-sm.navbar-dropdown-primary ul.navbar-nav > li > a {
    color: rgba(250, 250, 250, 0.8);
  }
  .navbar-expand-sm.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
    color: #fff;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: #fff;
    margin-top: 0px;
    z-index: 1000;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-md .navbar-collapse .navbar-nav {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    padding: 15px;
  }
  .navbar-expand-md ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
  }
  .navbar-expand-md ul.navbar-nav li:last-child {
    border: none;
  }
  .navbar-expand-md ul.navbar-nav li + li {
    margin-left: 0px;
  }
  .navbar-expand-md ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.show:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-md ul.navbar-nav li > a:hover:after, .navbar-expand-md ul.navbar-nav li > a.active:after {
    content: none !important;
    width: 0px !important;
  }
  .navbar-expand-md ul.navbar-nav li.dropdown > .dropdown-toggle .arrow {
    display: block;
  }
  .navbar-expand-md ul.navbar-nav > li > a:not(.btn) {
    height: auto;
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown:hover > a:after {
    content: none;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-50%, -50%) rotate(134deg);
    transform: translate(-50%, -50%) rotate(134deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown .dropdown-menu {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0px 0px 0px 15px;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    margin: 0;
  }
  .navbar-expand-md ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    padding: 0px 15px;
  }
  .navbar-expand-md.navbar-text-light .navbar-collapse {
    background: rgba(0, 0, 0, 0.95);
  }
  .navbar-expand-md.navbar-text-light .navbar-collapse ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.15);
  }
  .navbar-expand-md.navbar-dropdown-dark .navbar-collapse {
    background-color: #252A2C;
  }
  .navbar-expand-md.navbar-dropdown-primary .navbar-collapse {
    background-color: #0366d6;
  }
  .navbar-expand-md.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    background-color: #0366d6;
  }
  .navbar-expand-md.navbar-dropdown-dark ul.navbar-nav li {
    border-color: #444;
  }
  .navbar-expand-md.navbar-dropdown-dark ul.navbar-nav > li > a {
    color: #a3a2a2;
  }
  .navbar-expand-md.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
    color: #fff;
  }
  .navbar-expand-md.navbar-dropdown-primary ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.2);
  }
  .navbar-expand-md.navbar-dropdown-primary ul.navbar-nav > li > a {
    color: rgba(250, 250, 250, 0.8);
  }
  .navbar-expand-md.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
    color: #fff;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: #fff;
    margin-top: 0px;
    z-index: 1000;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    padding: 15px;
  }
  .navbar-expand-lg ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
  }
  .navbar-expand-lg ul.navbar-nav li:last-child {
    border: none;
  }
  .navbar-expand-lg ul.navbar-nav li + li {
    margin-left: 0px;
  }
  .navbar-expand-lg ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.show:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-lg ul.navbar-nav li > a:hover:after, .navbar-expand-lg ul.navbar-nav li > a.active:after {
    content: none !important;
    width: 0px !important;
  }
  .navbar-expand-lg ul.navbar-nav li.dropdown > .dropdown-toggle .arrow {
    display: block;
  }
  .navbar-expand-lg ul.navbar-nav > li > a:not(.btn) {
    height: auto;
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown:hover > a:after {
    content: none;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-50%, -50%) rotate(134deg);
    transform: translate(-50%, -50%) rotate(134deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown .dropdown-menu {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0px 0px 0px 15px;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    margin: 0;
  }
  .navbar-expand-lg ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    padding: 0px 15px;
  }
  .navbar-expand-lg.navbar-text-light .navbar-collapse {
    background: rgba(0, 0, 0, 0.95);
  }
  .navbar-expand-lg.navbar-text-light .navbar-collapse ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.15);
  }
  .navbar-expand-lg.navbar-dropdown-dark .navbar-collapse {
    background-color: #252A2C;
  }
  .navbar-expand-lg.navbar-dropdown-primary .navbar-collapse {
    background-color: #0366d6;
  }
  .navbar-expand-lg.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    background-color: #0366d6;
  }
  .navbar-expand-lg.navbar-dropdown-dark ul.navbar-nav li {
    border-color: #444;
  }
  .navbar-expand-lg.navbar-dropdown-dark ul.navbar-nav > li > a {
    color: #a3a2a2;
  }
  .navbar-expand-lg.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
    color: #fff;
  }
  .navbar-expand-lg.navbar-dropdown-primary ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.2);
  }
  .navbar-expand-lg.navbar-dropdown-primary ul.navbar-nav > li > a {
    color: rgba(250, 250, 250, 0.8);
  }
  .navbar-expand-lg.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
    color: #fff;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: #fff;
    margin-top: 0px;
    z-index: 1000;
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    padding: 15px;
  }
  .navbar-expand-xl ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
  }
  .navbar-expand-xl ul.navbar-nav li:last-child {
    border: none;
  }
  .navbar-expand-xl ul.navbar-nav li + li {
    margin-left: 0px;
  }
  .navbar-expand-xl ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.show:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-xl ul.navbar-nav li > a:hover:after, .navbar-expand-xl ul.navbar-nav li > a.active:after {
    content: none !important;
    width: 0px !important;
  }
  .navbar-expand-xl ul.navbar-nav li.dropdown > .dropdown-toggle .arrow {
    display: block;
  }
  .navbar-expand-xl ul.navbar-nav > li > a:not(.btn) {
    height: auto;
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 8px 0;
    position: relative;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown:hover > a:after {
    content: none;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-50%, -50%) rotate(134deg);
    transform: translate(-50%, -50%) rotate(134deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown .dropdown-menu {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0px 0px 0px 15px;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    margin: 0;
  }
  .navbar-expand-xl ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    padding: 0px 15px;
  }
  .navbar-expand-xl.navbar-text-light .navbar-collapse {
    background: rgba(0, 0, 0, 0.95);
  }
  .navbar-expand-xl.navbar-text-light .navbar-collapse ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.15);
  }
  .navbar-expand-xl.navbar-dropdown-dark .navbar-collapse {
    background-color: #252A2C;
  }
  .navbar-expand-xl.navbar-dropdown-primary .navbar-collapse {
    background-color: #0366d6;
  }
  .navbar-expand-xl.navbar-dropdown-primary ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    background-color: #0366d6;
  }
  .navbar-expand-xl.navbar-dropdown-dark ul.navbar-nav li {
    border-color: #444;
  }
  .navbar-expand-xl.navbar-dropdown-dark ul.navbar-nav > li > a {
    color: #a3a2a2;
  }
  .navbar-expand-xl.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
    color: #fff;
  }
  .navbar-expand-xl.navbar-dropdown-primary ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.2);
  }
  .navbar-expand-xl.navbar-dropdown-primary ul.navbar-nav > li > a {
    color: rgba(250, 250, 250, 0.8);
  }
  .navbar-expand-xl.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
    color: #fff;
  }
}
/* =================================== */
/*  5. Elements
/* =================================== */
/*=== 5.1 List Style ===*/
.list-style-1 > li {
  position: relative;
  list-style-type: none;
  line-height: 24px;
  
}
.list-style-1 > li:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -15px;
  border-color: #000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 6px;
  height: 6px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

/* ol {
  counter-reset: item;
  padding-right: 1em;
}
li {
  display:block;
  
}
ol li{
  width: 95%;
  padding-left: 1em;
  
}
ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  
} */

.list-style-2 {
  padding: 0;
}

.list-style-2 > li {
  list-style-type: none;
  border-bottom: 1px solid #eaeaea;
  padding-top: 12px;
  padding-bottom: 12px;
}

.list-style-2.list-style-light > li {
  border-bottom: 1px solid rgba(250, 250, 250, 0.12);
}

/*=== 5.2 Changelog ===*/
.changelog {
  list-style: none;
  padding: 0;
}
.changelog .badge {
  width: 90px;
  margin-right: 10px;
  border-radius: .20rem;
  text-transform: uppercase;
}
.changelog li {
  line-height: 1.8;
}

/*=== 5.3 Accordion & Toggle ===*/
.accordion .card {
  border: none;
  margin-bottom: 16px;
  margin-bottom: 1rem;
  background-color: transparent;
}
.accordion .card-header {
  padding: 0;
  border: none;
  background: none;
}
.accordion .card-header a {
  font-size: 16px;
  font-weight: normal;
  padding: 1rem 1.25rem 1rem 2.25rem;
  display: block;
  border-radius: 4px;
  position: relative;
}
.accordion .card-header a:hover {
  text-decoration: none;
}
.accordion .card-header a:hover.collapsed {
  color: #0366d6 !important;
}
.accordion:not(.accordion-alternate) .card-header a {
  background-color: #0366d6;
  color: #fff;
}
.accordion:not(.accordion-alternate) .card-header a.collapsed {
  background-color: #f1f2f4;
  color: #4c4d4d;
}
.accordion .card-header a:before {
  position: absolute;
  content: " ";
  left: 20px;
  top: calc(50% + 2px);
  width: 9px;
  height: 9px;
  border-color: #CCC;
  border-top: 2px solid;
  border-right: 2px solid;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.accordion .card-header a.collapsed:before {
  top: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.accordion .card-body {
  line-height: 26px;
}
.accordion.arrow-right .card-header a {
  padding-left: 1.25rem;
}
.accordion.arrow-right .card-header a:before {
  right: 15px;
  left: auto;
}
.accordion.accordion-alternate .card {
  margin: 0;
}
.accordion.accordion-alternate .card-header a {
  padding-left: 1.40rem;
  border-top: 1px solid #e4e9ec;
  border-radius: 0px;
}
.accordion.accordion-alternate .card:first-of-type .card-header a {
  border-top: 0px;
}
.accordion.accordion-alternate .card-header a:before {
  left: 6px;
}
.accordion.accordion-alternate .card-header a.collapsed {
  color: #4c4d4d;
}
.accordion.accordion-alternate .card-body {
  padding: 0rem 0 1rem 1.25rem;
}
.accordion.accordion-alternate.arrow-right .card-header a {
  padding-left: 0;
}
.accordion.accordion-alternate.arrow-right .card-header a:before {
  right: 0px;
  left: auto;
}
.accordion.toggle .card-header a:before {
  content: "-";
  border: none;
  font-size: 20px;
  height: auto;
  top: calc(50% + 2px);
  width: auto;
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg);
}
.accordion.toggle .card-header a.collapsed:before {
  content: "+";
  top: calc(50% - 1px);
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}
.accordion.accordion-alternate.style-2 .card-header a {
  padding-left: 0px;
}
.accordion.accordion-alternate.style-2 .card-header a:before {
  right: 2px;
  left: auto;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
  top: 50%;
}
.accordion.accordion-alternate.style-2 .card-header a.collapsed:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.accordion.accordion-alternate.style-2 .card-body {
  padding-left: 0px;
}
.accordion.accordion-alternate.popularRoutes .card-header .nav {
  margin-top: 3px;
}
.accordion.accordion-alternate.popularRoutes .card-header .nav a {
  font-size: 14px;
}
.accordion.accordion-alternate.popularRoutes .card-header a {
  padding: 0px 8px 0px 0px;
  border: none;
  font-size: inherit;
}
.accordion.accordion-alternate.popularRoutes .card-header a:before {
  content: none;
}
.accordion.accordion-alternate.popularRoutes .card-header h5 {
  cursor: pointer;
}
.accordion.accordion-alternate.popularRoutes .card-header h5:before {
  position: absolute;
  content: " ";
  right: 0px;
  top: 24px;
  width: 10px;
  height: 10px;
  opacity: 0.6;
  border-top: 2px solid;
  border-right: 2px solid;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.accordion.accordion-alternate.popularRoutes .card-header h5.collapsed:before {
  top: 24px;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.accordion.accordion-alternate.popularRoutes .card-body {
  padding: 0;
}
.accordion.accordion-alternate.popularRoutes .card {
  border-bottom: 2px solid #e4e9ec;
  padding: 15px 0px;
}
.accordion.accordion-alternate.popularRoutes .routes-list {
  margin: 1rem 0px 0px 0px;
  padding: 0px;
  list-style: none;
}
.accordion.accordion-alternate.popularRoutes .routes-list a {
  color: inherit;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.accordion.accordion-alternate.popularRoutes .routes-list a:hover {
  color: #0071cc;
  text-decoration: underline;
}

/* 5.4 Nav */
.nav .nav-item .nav-link {
  color: #222222;
}

.nav.nav-light .nav-item .nav-link {
  color: #ddd;
}

.nav:not(.nav-pills) .nav-item .nav-link.active, .nav:not(.nav-pills) .nav-item .nav-link:hover {
  color: #0366d6;
}

.nav-pills .nav-link:not(.active):hover {
  color: #0366d6;
}

.nav-pills .nav-link.active, .nav-pills.nav-light .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
}

.nav.nav-separator .nav-item .nav-link {
  position: relative;
}

.nav.nav-separator .nav-item + .nav-item .nav-link:after {
  height: 14px;
  width: 1px;
  content: ' ';
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

.nav.nav-separator.nav-separator-light .nav-item + .nav-item .nav-link:after {
  background-color: rgba(250, 250, 250, 0.2);
}

.nav.nav-sm .nav-item .nav-link {
  font-size: 14px;
}

/*=== 5.5 Tabs ===*/
.nav-tabs {
  border-bottom: 1px solid #d7dee3;
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  background: transparent;
  position: relative;
  border-radius: 0;
  padding: 0.6rem 1rem;
  color: #7b8084;
  white-space: nowrap !important;
}
.nav-tabs .nav-item .nav-link.active {
  color: #0c2f55;
}
.nav-tabs .nav-item .nav-link.active:after {
  height: 2px;
  width: 100%;
  content: ' ';
  background-color: #0366d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.nav-tabs .nav-item .nav-link:not(.active):hover {
  color: #0366d6;
}
.nav-tabs.flex-column {
  border-right: 1px solid #d7dee3;
  border-bottom: 0px;
  padding: 1.5rem 0;
}
.nav-tabs.flex-column .nav-item .nav-link {
  border: 1px solid #d7dee3;
  border-right: 0px;
  background-color: #f6f7f8;
  font-size: 14px;
  padding: 0.75rem 1rem;
  color: #535b61;
}
.nav-tabs.flex-column .nav-item:first-of-type .nav-link {
  border-top-left-radius: 4px;
}
.nav-tabs.flex-column .nav-item:last-of-type .nav-link {
  border-bottom-left-radius: 4px;
}
.nav-tabs.flex-column .nav-item .nav-link.active {
  background-color: transparent;
  color: #0366d6;
}
.nav-tabs.flex-column .nav-item .nav-link.active:after {
  height: 100%;
  width: 2px;
  background: #fff;
  right: -1px;
  left: auto;
}

.nav-tabs:not(.flex-column) {
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}
.nav-tabs:not(.flex-column) .nav-item {
  margin-bottom: 0px;
}

@media (max-width: 575.98px) {
  .nav-tabs .nav-item .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    font-size: 0.875rem;
  }
}
/*=== 5.6 Popup Img ===*/
.popup-img img {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.popup-img:hover img {
  opacity: 0.8;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

/*=== 5.7 Featured Box ===*/
.featured-box {
  box-sizing: border-box;
  position: relative;
}
.featured-box h3, .featured-box h4 {
  font-size: 1.25rem;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.featured-box:not(.style-5) .featured-box-icon {
  display: inline-block;
  font-size: 48px;
  min-width: 55px;
  min-height: 55px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0.8rem;
  color: #4c4d4d;
  border-radius: 0;
}
.featured-box.style-1, .featured-box.style-2, .featured-box.style-3 {
  padding-left: 50px;
  padding-top: 8px;
}
.featured-box.style-1 .featured-box-icon, .featured-box.style-2 .featured-box-icon, .featured-box.style-3 .featured-box-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  font-size: 30px;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  text-align: center;
}
.featured-box.style-2 p {
  margin-left: -50px;
}
.featured-box.style-3 {
  padding-left: 90px;
  padding-top: 0px;
}
.featured-box.style-3 .featured-box-icon {
  width: 70px;
  height: 70px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.featured-box.style-4 {
  text-align: center;
}
.featured-box.style-4 .featured-box-icon {
  margin: 0 auto 24px;
  margin: 0 auto 1.5rem;
  width: 120px;
  height: 120px;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
}
.featured-box.style-5 {
  text-align: center;
  background: #fff;
  border: 1px solid #f0f2f3;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.featured-box.style-5:hover {
  border: 1px solid #ebeded;
  -webkit-box-shadow: 0px 5px 1.5rem rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 1.5rem rgba(0, 0, 0, 0.15);
}
.featured-box.style-5 h3 {
  background: #f1f5f6;
  font-size: 16px;
  padding: 8px 0;
  margin-bottom: 0px;
}
.featured-box.style-5 .featured-box-icon {
  font-size: 50px;
  margin: 44px 0px;
}

.featured-box.featured-box-reverse {
  text-align: right;
}
.featured-box.featured-box-reverse.style-1, .featured-box.featured-box-reverse.style-2 {
  padding-right: 50px;
  padding-left: 0px;
}
.featured-box.featured-box-reverse.style-1 .featured-box-icon, .featured-box.featured-box-reverse.style-2 .featured-box-icon {
  left: auto;
  right: 0px;
}
.featured-box.featured-box-reverse.style-2 p {
  margin-right: -50px;
  margin-left: 0;
}
.featured-box.featured-box-reverse.style-3 {
  padding-left: 0;
  padding-right: 90px;
}
.featured-box.featured-box-reverse.style-3 .featured-box-icon {
  left: auto;
  right: 0px;
}

.featured-box.featured-box-reverse-sm {
  text-align: right;
}
.featured-box.featured-box-reverse-sm.style-1, .featured-box.featured-box-reverse-sm.style-2 {
  padding-right: 50px;
  padding-left: 0px;
}
.featured-box.featured-box-reverse-sm.style-1 .featured-box-icon, .featured-box.featured-box-reverse-sm.style-2 .featured-box-icon {
  left: auto;
  right: 0px;
}
.featured-box.featured-box-reverse-sm.style-2 p {
  margin-right: -50px;
  margin-left: 0;
}
.featured-box.featured-box-reverse-sm.style-3 {
  padding-left: 0;
  padding-right: 90px;
}
.featured-box.featured-box-reverse-sm.style-3 .featured-box-icon {
  left: auto;
  right: 0px;
}

@media (min-width: 576px) {
  .featured-box.featured-box-reverse-md {
    text-align: right;
  }
  .featured-box.featured-box-reverse-md.style-1, .featured-box.featured-box-reverse-md.style-2 {
    padding-right: 50px;
    padding-left: 0px;
  }
  .featured-box.featured-box-reverse-md.style-1 .featured-box-icon, .featured-box.featured-box-reverse-md.style-2 .featured-box-icon {
    left: auto;
    right: 0px;
  }
  .featured-box.featured-box-reverse-md.style-2 p {
    margin-right: -50px;
    margin-left: 0;
  }
  .featured-box.featured-box-reverse-md.style-3 {
    padding-left: 0;
    padding-right: 90px;
  }
  .featured-box.featured-box-reverse-md.style-3 .featured-box-icon {
    left: auto;
    right: 0px;
  }
}
@media (min-width: 768px) {
  .featured-box.featured-box-reverse-lg {
    text-align: right;
  }
  .featured-box.featured-box-reverse-lg.style-1, .featured-box.featured-box-reverse-lg.style-2 {
    padding-right: 50px;
    padding-left: 0px;
  }
  .featured-box.featured-box-reverse-lg.style-1 .featured-box-icon, .featured-box.featured-box-reverse-lg.style-2 .featured-box-icon {
    left: auto;
    right: 0px;
  }
  .featured-box.featured-box-reverse-lg.style-2 p {
    margin-right: -50px;
    margin-left: 0;
  }
  .featured-box.featured-box-reverse-lg.style-3 {
    padding-left: 0;
    padding-right: 90px;
  }
  .featured-box.featured-box-reverse-lg.style-3 .featured-box-icon {
    left: auto;
    right: 0px;
  }
}
@media (min-width: 992px) {
  .featured-box.featured-box-reverse-xl {
    text-align: right;
  }
  .featured-box.featured-box-reverse-xl.style-1, .featured-box.featured-box-reverse-xl.style-2 {
    padding-right: 50px;
    padding-left: 0px;
  }
  .featured-box.featured-box-reverse-xl.style-1 .featured-box-icon, .featured-box.featured-box-reverse-xl.style-2 .featured-box-icon {
    left: auto;
    right: 0px;
  }
  .featured-box.featured-box-reverse-xl.style-2 p {
    margin-right: -50px;
    margin-left: 0;
  }
  .featured-box.featured-box-reverse-xl.style-3 {
    padding-left: 0;
    padding-right: 90px;
  }
  .featured-box.featured-box-reverse-xl.style-3 .featured-box-icon {
    left: auto;
    right: 0px;
  }
}
/* =================================== */
/*  6. Footer
/* =================================== */
#footer {
  background: #fff;
  color: #252b33;
  margin-left: 260px;
  padding: 66px 0px;
  padding: 4.125rem 0;
}
#footer .nav .nav-item {
  display: inline-block;
  line-height: 12px;
  margin: 0;
}
#footer .nav .nav-item .nav-link {
  color: #252b33;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav .nav-item .nav-link:focus {
  color: #0366d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav .nav-item:first-child .nav-link {
  padding-left: 0px;
}
#footer .nav .nav-item:last-child .nav-link {
  padding-right: 0px;
}
#footer .nav .nav-link:hover {
  color: #0366d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .footer-copyright {
  border-top: 1px solid #e2e8ea;
  padding: 0px 0px;
  color: #67727c;
}
#footer .footer-copyright .nav .nav-item .nav-link {
  color: #67727c;
}
#footer .footer-copyright .nav .nav-link:hover {
  color: #0366d6;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav.flex-column .nav-item {
  padding: 0px;
}
#footer .nav.flex-column .nav-item .nav-link {
  margin: 0.7rem 0px;
}
#footer.footer-text-light {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light .nav .nav-item .nav-link {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light .nav .nav-item .nav-link:hover {
  color: #fafafa;
}
#footer.footer-text-light .footer-copyright {
  border-color: rgba(250, 250, 250, 0.15);
  color: rgba(250, 250, 250, 0.5);
}
#footer.footer-text-light:not(.bg-primary) .social-icons-light.social-icons li a {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light:not(.bg-primary) .social-icons-light.social-icons li a:hover {
  color: #fafafa;
}
#footer.footer-text-light.bg-primary {
  color: #fff;
}
#footer.footer-text-light.bg-primary .nav .nav-item .nav-link {
  color: #fff;
}
#footer.footer-text-light.bg-primary .nav .nav-item .nav-link:hover {
  color: rgba(250, 250, 250, 0.7);
}
#footer.footer-text-light.bg-primary .footer-copyright {
  border-color: rgba(250, 250, 250, 0.15);
  color: rgba(250, 250, 250, 0.9);
}
#footer.footer-text-light.bg-primary :not(.social-icons) a {
  color: #fff;
}
#footer.footer-text-light.bg-primary :not(.social-icons) a:hover {
  color: rgba(250, 250, 250, 0.7);
}

@media (max-width: 767.98px) {
  #footer {
    margin-left: 0px;
  }
}
/*=== 6.1 Social Icons ===*/
.social-icons {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.social-icons li {
  margin: 0px 6px;
  padding: 0;
  overflow: visible;
}
.social-icons li a {
  display: block;
  height: 26px;
  line-height: 26px;
  width: 26px;
  font-size: 18px;
  text-align: center;
  color: #4d555a;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.social-icons li i {
  line-height: inherit;
}
.social-icons.social-icons-sm li {
  margin: 0px 4px;
}
.social-icons.social-icons-sm li a {
  font-size: 15px;
  width: 22px;
}
.social-icons.social-icons-lg li a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 22px;
}
.social-icons.social-icons-light li a {
  color: #eee;
}
.social-icons.social-icons-muted li a {
  color: #aab1b8;
}
.social-icons li:hover a {
  color: #999;
}

/*=== 6.2 Back to Top ===*/
#back-to-top {
  display: none;
  position: fixed;
  z-index: 1030;
  bottom: 8px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.22);
  text-align: center;
  color: #fff;
  font-size: 14px;
  width: 36px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}
#back-to-top:hover {
  background-color: #0366d6;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 575.98px) {
  #back-to-top {
    z-index: 1029;
  }
}
/* =================================== */
/*  Extras
/* =================================== */
/* Bootstrap Specific */
.form-control, .custom-select {
  border-color: #dae1e3;
  font-size: 16px;
  color: #656565;
}

.form-control:not(.form-control-sm) {
  padding: .810rem .96rem;
  height: inherit;
}

.form-control-sm {
  font-size: 14px;
}

.icon-inside {
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
  pointer-events: none;
  font-size: 18px;
  font-size: 1.125rem;
  color: #c4c3c3;
  z-index: 3;
}

.form-control-sm + .icon-inside {
  font-size: 0.875rem !important;
  font-size: 14px;
  top: calc(50% - 13px);
}

select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
  height: auto;
  padding-top: .700rem;
  padding-bottom: .700rem;
}

.custom-select:not(.custom-select-sm) {
  height: calc(3.05rem + 2px);
  padding-top: .700rem;
  padding-bottom: .700rem;
}

.col-form-label-sm {
  font-size: 13px;
}

.custom-select-sm {
  padding-left: 5px !important;
  font-size: 14px;
}

.custom-select:not(.custom-select-sm).border-0 {
  height: 3.00rem;
}

.form-control:focus, .custom-select:focus {
  -webkit-box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.form-control:focus[readonly] {
  box-shadow: none;
}

.input-group-text {
  border-color: #dae1e3;
  background-color: #f1f5f6;
  color: #656565;
}

.form-control::-webkit-input-placeholder {
  color: #b1b4b6;
}
.form-control:-moz-placeholder {
  /* FF 4-18 */
  color: #b1b4b6;
}
.form-control::-moz-placeholder {
  /* FF 19+ */
  color: #b1b4b6;
}
.form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: #b1b4b6;
}

/* Form Dark */
.form-dark .form-control, .form-dark .custom-select {
  border-color: #232a31;
  background: #232a31;
  color: #fff;
}
.form-dark .form-control:focus {
  border-color: #80bdff !important;
}
.form-dark .form-control::-webkit-input-placeholder {
  color: #777b7f;
}
.form-dark .form-control:-moz-placeholder {
  /* FF 4-18 */
  color: #777b7f;
}
.form-dark .form-control::-moz-placeholder {
  /* FF 19+ */
  color: #777b7f;
}
.form-dark .form-control:-ms-input-placeholder, .form-dark .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: #777b7f;
}
.form-dark .custom-select {
  color: #777b7f;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 13px 15px;
  border-color: #232a31;
  background-color: #232a31;
}
.form-dark .icon-inside {
  color: #777b7f;
}

/*  Input with only bottom border  */
.form-border .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  padding-left: 0px !important;
  color: black;
}
.form-border .form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.form-border .form-control:-moz-placeholder {
  /* FF 4-18 */
  color: rgba(0, 0, 0, 0.4);
}
.form-border .form-control::-moz-placeholder {
  /* FF 19+ */
  color: rgba(0, 0, 0, 0.4);
}
.form-border .form-control:-ms-input-placeholder, .form-border .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.4);
}
.form-border .custom-select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  padding-left: 0px;
  color: rgba(0, 0, 0, 0.4);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(0,0,0,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 13px 15px;
}
.form-border .form-control:focus, .form-border .custom-select:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.7);
}
.form-border .form-control:not(output):-moz-ui-invalid:not(:focus), .form-border .form-control:not(output):-moz-ui-invalid:-moz-focusring:not(:focus), .form-border .custom-select:not(output):-moz-ui-invalid:not(:focus), .form-border .custom-select:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
  border-bottom: 2px solid #b00708;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.form-border .icon-inside {
  color: rgba(0, 0, 0, 0.25);
}
.form-border select option {
  color: #666;
}

.form-border-light .form-control {
  border-bottom: 2px solid rgba(250, 250, 250, 0.3);
  color: #fafafa;
}
.form-border-light .form-control::-webkit-input-placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.form-border-light .form-control:-moz-placeholder {
  /* FF 4-18 */
  color: rgba(250, 250, 250, 0.7);
}
.form-border-light .form-control::-moz-placeholder {
  /* FF 19+ */
  color: rgba(250, 250, 250, 0.7);
}
.form-border-light .form-control:-ms-input-placeholder, .form-border-light .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(250, 250, 250, 0.7);
}
.form-border-light .custom-select {
  border-bottom: 2px solid rgba(250, 250, 250, 0.3);
  color: #fafafa;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.6)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 13px 15px;
}
.form-border-light .form-control:focus, .form-border-light .custom-select:focus {
  border-bottom: 2px solid rgba(250, 250, 250, 0.8);
}
.form-border-light .icon-inside {
  color: #999;
}
.form-border-light select option {
  color: #333;
}

.input-group-append .btn, .input-group-prepend .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.input-group-append .btn:hover, .input-group-prepend .btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .input-group > .input-group-append > .btn, .input-group > .input-group-prepend > .btn {
    padding: 0 0.75rem;
  }
}
.bg-primary, .badge-primary {
  background-color: #0366d6 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.text-primary, .btn-light, .btn-outline-light:hover, .btn-link, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active {
  color: #0366d6 !important;
}

.btn-link:hover {
  color: #0355b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-light {
  color: #dee3e4 !important;
}

.text-body {
  color: #4c4d4d !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0355b3 !important;
}

.border-primary {
  border-color: #0366d6 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.btn-primary {
  background-color: #0366d6;
  border-color: #0366d6;
}
.btn-primary:hover {
  background-color: #0355b3;
  border-color: #0355b3;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #0355b3;
  border-color: #0355b3;
}

.btn-primary.focus, .btn-primary:focus {
  background-color: #0355b3;
  border-color: #0355b3;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #0366d6;
  border-color: #0366d6;
}
.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active:hover, .btn-outline-primary:not(:disabled):not(.disabled):active:hover {
  background-color: #0366d6;
  border-color: #0366d6;
  color: #fff;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.progress-bar,
.nav-pills .nav-link.active, .nav-pills .show > .nav-link, .dropdown-item.active, .dropdown-item:active {
  background-color: #0366d6;
}

.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #0366d6;
  border-color: #0366d6;
}

.list-group-item.active {
  background-color: #0366d6;
  border-color: #0366d6;
}

.page-link {
  color: #0366d6;
}
.page-link:hover {
  color: #0355b3;
}

/* Pagination */
.page-link {
  border: none;
  border-radius: 0.25rem;
  margin: 0 0.22rem;
  font-size: 16px;
  font-size: 1rem;
}
.page-link:hover {
  background-color: #e9eff0;
}

/* Vertical Multilple input group */
.vertical-input-group .input-group:first-child {
  padding-bottom: 0;
}
.vertical-input-group .input-group:first-child * {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vertical-input-group .input-group:last-child {
  padding-top: 0;
}
.vertical-input-group .input-group:last-child * {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.vertical-input-group .input-group:not(:last-child):not(:first-child) {
  padding-top: 0;
  padding-bottom: 0;
}
.vertical-input-group .input-group:not(:last-child):not(:first-child) * {
  border-radius: 0;
}
.vertical-input-group .input-group:not(:first-child) * {
  border-top: 0;
}

/* styles-switcher */
#styles-switcher {
  background: #fff;
  width: 202px;
  position: fixed;
  top: 35%;
  z-index: 99;
  padding: 20px;
  left: -202px;
}
#styles-switcher ul {
  padding: 0;
}
#styles-switcher ul li {
  list-style-type: none;
  width: 25px;
  height: 25px;
  margin: 4px 2px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
#styles-switcher ul li.blue {
  background: #007bff;
}
#styles-switcher ul li.brown {
  background: #795548;
}
#styles-switcher ul li.purple {
  background: #6f42c1;
}
#styles-switcher ul li.indigo {
  background: #6610f2;
}
#styles-switcher ul li.red {
  background: #dc3545;
}
#styles-switcher ul li.orange {
  background: #fd7e14;
}
#styles-switcher ul li.yellow {
  background: #ffc107;
}
#styles-switcher ul li.green {
  background: #28a745;
}
#styles-switcher ul li.teal {
  background: #20c997;
}
#styles-switcher ul li.cyan {
  background: #17a2b8;
}
#styles-switcher ul li.active {
  transform: scale(0.7);
  cursor: default;
}
#styles-switcher .switcher-toggle {
  position: absolute;
  background: #333;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 0px 4px 4px 0;
  right: -40px;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 0;
}
#styles-switcher #reset-color {
  background: #e83e8c;
}

input:-internal-autofill-selected {
  background-color: transparent;
}

#styles-switcher.right {
  left: auto;
  right: -202px;
}

#styles-switcher.right .switcher-toggle {
  right: auto;
  left: -40px;
  border-radius: 4px 0px 0px 4px;
}
